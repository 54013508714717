* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* navbar */
.navbar .nav-item a:hover {
    border-bottom: 2px solid hsl(18, 96%, 51%);
    color: hsl(18, 96%, 51%) 
    !important;
}

/* home */
.carousel .pic {
    /* height: 78vh; */
}

p {
    text-align: justify;
}
/* about */
.about  h6{
    text-align: justify;
}

.programs a {
    color: hsl(18, 96%, 51%) !important;
    font-size: 20px;
    font-weight: bold;
}
/* impacts */
.impact-bg {
    background:hsl(0deg 0% 93.7%);
}
.imapct h1,
h2 {
    color: hsl(18, 96%, 51%);
}

/* program */
.programs h6, .impact-bg h6{
  text-align: justify;
}
/* gallery */

.gallery {
    position: relative;
    background: #ffffff;
    padding: 70px 0px 50px;
}

.gallery-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
   

}

.gallery .image-box {
    position: relative;
    float: left;
    margin-bottom: 0px;
    display: block;
    border: 2px solid #ffffff;
    width: 25%;
    margin-bottom:8px;
}

.gallery .inner-box {
    position: relative;
    width: 100%;
    display: block;
    background: #ffffff;
    overflow: hidden; 
  
 
}

figure {
    margin: 0;
}
 figure {
	background: #da6d0d;
    
}
 figure img {
	opacity: 1;
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
 figure:hover img {
	opacity: .5;
}

.gallery .image img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
 
}
.gallery-footer {
    background: hsl(18, 96%, 51%);
    color: white;
}



@media screen and (max-width:600px) {
    .carousel .pic {
        /* height: 30vh; */
    }

}