
.eye-camp {
    /* position: relative; */
    background: #ffffff;
    padding: 70px 0px 50px;
}

.gallery-box1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;


}

.eye-camp .image-box1 {
    /* position: relative; */
    float: left;
    margin-bottom: 0px;
    display: block;
    border: 2px solid #ffffff;
    width: 20%;
    cursor: pointer;
}

@media screen and (max-width:600px) {
    .eye-camp .image-box1 {
        width: 50%;

    }

}