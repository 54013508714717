body{
    overflow-x: hidden;
}

.image-modal {
    height: 100vh;
    position: sticky;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.42);
    backdrop-filter: blur(4px);
    cursor: pointer;
}