@import url('https://fonts.googleapis.com/css2?family=Belanosima:wght@400;600;700&family=Josefin+Sans:ital,wght@0,400;0,700;1,600&family=Kaisei+Decol:wght@400;500;700&family=Lato:wght@300;400;700&family=Poppins:wght@300;400;500&display=swap');
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Lato', sans-serif;
}
