.Voluntee-box{
   
}
.Voluntee-box:hover{
    background-color:  hsl(17, 47%, 91%)!important; 
    transition: background-color 1s ease; 
    cursor: pointer;
}
.Voluntee-box img{
    height: 150px;
    width: 150px;
    border-radius: 60%;
    border: 1px solid gray;
   
}

@media screen and (max-width:600px) {
    .Voluntee-box img{
        height: 90px;
        width: 110px;
        border-radius: 60%;
    
    }

}